import Vue from 'vue';
import 'pretty-checkbox';
import PrettyCheckbox from 'pretty-checkbox-vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import axios from 'axios';
import App from './App.vue';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import VueCookies from 'vue-cookies'
import VueSocketIO from 'vue-socket.io';
import SocketIO from 'socket.io-client';
import VueObserveVisibility from 'vue-observe-visibility'


const options = { path: '/pajatodo/ping' }; //Options object to pass into SocketIO

Vue.use(
  new VueSocketIO({
      //debug: true,
      connection: SocketIO(window.location.protocol + '//' + window.location.host, options), //options object is Optional
  })
);

const http = axios.create({
  baseURL: process.env.BACKEND_URL ? process.env.BACKEND_URL : window.location.protocol + '//' + window.location.host + '/pajatodo',
});

Vue.prototype.$http = http;

Vue.use(PrettyCheckbox);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueLodash, { lodash: lodash });
Vue.use(VueCookies);
Vue.use(VueObserveVisibility);

Vue.$cookies.config('3650d');

Vue.config.productionTip = false;

import './assets/custom.scss';

new Vue({
  render: (h) => h(App),
}).$mount('#app');
