<template>
  <div class="col align-self-center pt-2">
    <div class="input-group mb-3" @submit.prevent>
      <input
        v-model="name"
        @keyup.enter="addTodo($event)"
        type="text"
        class="form-control"
        placeholder="Add a new todo..."
        aria-label="Type a new todo and press Enter or Add"
        aria-describedby="button-addon2"
      />
      <div class="input-group-append">
        <button @click="addTodo($event)" class="btn btn-primary" type="button" id="button-addon2" :disabled="name.length < 1">
          <b-icon icon="plus"></b-icon>Add
        </button>
      </div>
    </div>

    <i class="bi bi-chevron-right"></i>
  </div>
</template>
<script>
//import axios from "axios";
import bus from "./../bus.js";

export default {
  data() {
    return {
      activeList: "",
      name: "",
      createdBy: "",
      typing: false,
      maxOrder: 0
    };
  },

  created() {
    bus.$on("changeActiveList", id => {
      this.activeList = id;
    });
  },

  methods: {
    addTodo(event) {
      if (event) event.preventDefault();
      // let stuff = ["chicken", "crabs", "mice", "milk", "apples"];
      // if (!this.name) {
      //   this.name = stuff[Math.floor(Math.random() * stuff.length)];
      // }
      let todo = {
        createdBy: this.createdBy,
        name: this.name,
        list: this.activeList,
        done: false, //false by default
        order: this.maxOrder+1
      };
      var app = this;
      this.$http
        .post("/todo/", todo)
        .then(function() {
          app.clearTodo();
          app.refreshTodo();
          app.typing = false;
          app.updateTodoOrder();
        })
        .catch(error => {
          console.log(error);
        });
    },

    clearTodo() {
      this.name = "";
    },

    updateTodoOrder() {
      bus.$emit("updateTodoOrder");
    },

    refreshTodo() {
      bus.$emit("refreshTodo");
    }
  },
  mounted: function() {
    var app = this;
    bus.$on('notDoneTodos', function(event) {
      let maxOrderTodo = app._.maxBy(event, 'order');
      if (maxOrderTodo) {
        app.maxOrder = maxOrderTodo.order;
      }
      else {
        app.maxOrder = 0;
      }
    })
  }

};
</script>
<style lang="scss" scoped>
.underline {
  text-decoration: underline;
}

.input-group input {
  font-size: 1.2rem;

  &:focus {
    background: $white;
  }
}
</style>