<template>
  <div class="col">
    <transition-group v-bind:name="tGroupName">
      <div v-bind:key="todo._id" v-for="(todo, index) in notDoneTodos" class="form-row todo">
        <div class="input-group todo-inner" v-bind:key="todo._id">
          <div class="input-group-prepend">
            <div>
              <p-check
                class="p-svg p-curve p-bigger p-tada"
                @change="completeTodo(todo)"
                color="success"
              >
                <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                  <path
                    d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                    style="stroke: white;fill:white"
                  />
                </svg>
              </p-check>
            </div>

            <div class="todo-more" v-if="todo.showMore">
              <div class="todo-move-up">
                <b-icon-caret-up-fill v-if="index != 0" @click="todoMoveUp(todo, index);"></b-icon-caret-up-fill>
              </div>
              <div class="todo-move-down">
                <b-icon-caret-down-fill v-if="index != notDoneTodos.length - 1" @click="todoMoveDown(todo, index);"></b-icon-caret-down-fill>
              </div>
            </div>
          </div>

          <div class="form-control" style="height: auto">
            <input
              type="text"
              :class="todo.markedAsDone?'todo_done':''"
              v-model="todo.name"
              @keypress="todo.editing=true"
              @keyup.enter="updateTodo(todo); todo.Editable=false; forceUpdate()"
              @blur="updateTodo(todo); todo.Editable=false; forceUpdate()"
              v-show="todo.Editable"
            />
            <p
              class="todo-name"
              @click="todo.Editable = true; forceUpdate()"
              v-show="!todo.Editable"
            >{{todo.name}}</p>

            <p
              class="todo-description"
              v-show="!todo.showMore && todo.description"
              @click="todo.showMore = true; forceUpdate()"
              v-html="renderDescription(todo)"
            ></p>

            <div class="todo-more" v-if="todo.showMore">
              <textarea
                v-model="todo.description"
                @keyup="updateTodo(todo)"
                @blur="updateTodo(todo); todo.showMore = false; forceUpdate()"
              ></textarea>

              <div class="row">
                <div class="col-6">
                  <b-dropdown id="dropdown-left" text="Move to another list" variant="secondary-outline" class="">
                    <b-dropdown-item v-for="list in allListsExceptActive" v-bind:key="list._id" @click="moveTodoToList(todo, list)">{{ list.name }}</b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div class="row">
                <div class="col-6 align-self-center">
                  <div class="todo-by">Added on {{ formatDate(todo.created) }}</div>
                </div> 
              </div>
            </div>
          </div>

          <div class="input-group-append">
            <div class="todo-notes">
              <b-icon icon="three-dots" @click="todo.showMore=!todo.showMore; forceUpdate()"></b-icon>
            </div>
            <div class="input-group-text">
              <span
                class="input-group-addon addon-left todo-delete"
                title="Delete todo?"
                v-on:click="deleteTodo($event, todo)"
              >
                <b-icon icon="x"></b-icon>
              </span>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
    <div
      class="alert alert-secondary"
      v-show="notDoneTodos.length==0 && doneLoading && activeList != 0"
    >Good job! :) No todos to do!</div>
    <div role="tablist" v-show="doneTodosInList(activeList).length > 0">
      <b-card no-body>
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block href="#" v-b-toggle.listOfDoneTodos variant="outline-secondary">
            <span class="when-opened">Hide Completed in {{ nameForList(activeList) }}</span>
            <span class="when-closed">Show Completed in {{ nameForList(activeList) }}</span>
          </b-button>
        </b-card-header>
        <b-collapse id="listOfDoneTodos" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <ul>
                <li
                  v-bind:key="todo._id"
                  v-for="todo in doneTodosInList(activeList)"
                  class="done-todo"
                >
                  <span class="done-todo-name">{{ todo.name }}</span>
                  <span class="done-todo-notes" v-show="todo.description" @click="doneTodoShowDescription(todo)"><b-icon icon="file-text"></b-icon></span>
                  <!-- <span class="done-list-name">in {{ nameForList(todo.list) }}</span> -->
                  <!-- <span class="done-by">by {{ todo.createdBy }}</span> -->
                  <span class="done-todo-delete" @click="deleteCompletedTodo(todo)">Delete</span>
                  <span class="done-todo-restore" @click="undoDoneTodo(todo)">Undo</span>
                </li>
              </ul>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
import bus from "./../bus.js";
import Autolinker from "autolinker"; /* eslint-disable-line no-unused-vars */
import * as moment from "moment";

export default {
  data() {
    return {
      lists: [],
      activeList: "",
      todos: [],
      doneLoading: false,
      tGroupName: "default"
    };
  },

  computed: {
    // uncompleted todos in active list
    notDoneTodos: function() {
      var app = this;
      let filtered = this.todos.filter(function(todo) {
        return todo.done === false && todo.list === app.activeList;
      });
      let notDoneTodos = this._.orderBy(filtered, ["order"], ["desc"]);
      bus.$emit('notDoneTodos', notDoneTodos);
      return notDoneTodos;
    },

    // completed todos in active list
    doneTodos: function() {
      let doneTodos = this.todos.filter(function(todo) {
        return todo.done === true;
      });
      return this._.orderBy(doneTodos, ["completed"], ["desc"]);
    },
    allListsExceptActive: function() {
      var app = this;
      return app.lists.filter(function(list) {
        return list._id !== app.activeList;
      });
    }
  },

  created: function() {
    this.fetchTodo();
    this.listenToEvents();
  },

  updated: function() {
    this.$nextTick(function() {
      var app = this;
      if (this.doneLoading) {
        app.tGroupName = "todo-list";
      }
    });
  },

  //watch: {
  // $route: function() {
  //   var self = this;
  //   self.doneLoading = false;
  //   self.fetchData().then(function() {
  //     self.doneLoading = true;
  //   });
  // }
  //},

  methods: {
    fetchTodo() {
      this.$http.get("/todo/").then(response => {
        this.todos = response.data;
        this.doneLoading = true;
        bus.$emit("updateTodos", this.todos);
      });
    },

    // prevent "todo" not defined error in eslint
    // debouncing to prevent flooding for update on keydown when editing descriptions
    /* eslint-disable no-unused-vars */
    updateTodo: function(todo) {
      var app = this;
      if (todo.name.length > 0) {
        this._.debounce(function() {
          let id = todo._id;
          app.$http
            .put(`/todo/${id}`, todo)
            .then(response => {
              // tada
            })  
            .catch(error => {
              console.log(error);
            });
        }, 500)();
      }
    },
    /* eslint-disable no-unused-vars */

    updateTodoImmediate: function(todo) {
      var app = this;
      let id = todo._id;
      app.$http
        .put(`/todo/${id}`, todo)
        .then(response => {
          // tada
        })  
        .catch(error => {
          console.log(error);
        });
    },

    todoMoveUp: function(todo, index) {
      this.todoMove(todo, index, index-1);
    },

    todoMoveDown: function(todo, index) {
      this.todoMove(todo, index, index+1);
    },

    todoMove: function(todo, index, offset) {
      let replacerId = this.notDoneTodos[index]._id;
      let replaceeId = this.notDoneTodos[offset]._id;

      let replacerOrder = this.notDoneTodos[index].order;
      let replaceeOrder = this.notDoneTodos[offset].order;

      let replacerIdx = this._.findIndex(this.todos, { _id : replacerId });
      let replaceeIdx = this._.findIndex(this.todos, { _id : replaceeId });

      this.todos[replacerIdx].order = replaceeOrder;
      this.todos[replaceeIdx].order = replacerOrder;

      let replacer = this.todos[replacerIdx];
      let replacee = this.todos[replaceeIdx];

      this.$forceUpdate();

      this.updateTodoImmediate(replacer);
      this.updateTodoImmediate(replacee);
    },

    completeTodo(todo) {
      todo.markedAsDone = true;
      this.$forceUpdate();
      var app = this;
      setTimeout(function() {
        todo.done = true;
        todo.completed = Date.now();
        app.updateTodo(todo);
      }, 800);
    },

    undoDoneTodo(todo) {
      todo.done = false;
      todo.markedAsDone = false;
      this.updateTodo(todo);
    },

    deleteCompletedTodo(todo) {
      let id = todo._id;
      this.$http.delete(`/todo/${id}`).then(() => {
        this.fetchTodo();
      });
    },

    doneTodoShowDescription(todo) {
      alert(todo.description);
    },

    deleteTodo(event, todo) {
      if (event.shiftKey || confirm("Are you sure you want to delete this todo?")) {
        let id = todo._id;
        this.$http.delete(`/todo/${id}`).then(() => {
          this.fetchTodo();
        });
      }
    },

    listenToEvents() {
      bus.$on("refreshTodo", () => {
        this.fetchTodo(); //update todo
      });
      bus.$on("changeActiveList", id => {
        this.activeList = id;
      });
      bus.$on("updateLists", lists => {
        this.lists = lists;
        this.$forceUpdate();
      });
    },

    nameForList: function(listId) {
      var app = this;
      var name = "";
      Object.keys(app.lists).forEach(function(key) {
        if (app.lists[key]._id === listId) {
          name = app.lists[key].name;
        }
      });
      return name;
    },

    doneTodosInList: function(listId) {
      return this._.filter(this.doneTodos, { list: listId });
    },

    renderDescription: function(todo) {
      var autolinker = new Autolinker();
      return autolinker.link(todo.description);
    },

    moveTodoToList: function(todo, list) {
      todo.list = list._id;
      bus.$emit("updateTodos", this.todos);
      this.updateTodo(todo);
    },

    formatDate: function(ts) {
      return moment(parseInt(ts)).format("YYYY-MM-DD HH:mm");
    },

    forceUpdate: function() {
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss" scoped>
.input-group .todo_done {
  text-decoration: line-through;
  color: $gray-800;
}

[p-checkbox] {
  padding: 0;
  // margin-top: 1rem;
  // margin-right: .2rem;
  // margin-left: .2rem;
  margin: auto 0.2rem;
}

.input-group:hover {
  background: $gray-100;
}

.input-group * {
  font-size: 1.2rem;
}

.input-group-append {
  align-items: flex-start;
}

.input-group-prepend {
  flex-flow: column;
}

.input-group-append .input-group-text,
.input-group-prepend span {
  background: transparent;
  cursor: pointer;
  border: 0;
}

.input-group-append svg:hover {
  color: $gray-400;
}

input[type="text"] {
  background: transparent;
  border: 0;
  color: $gray-800;
  padding-bottom: 0.2rem;
  margin-bottom: 0.2rem;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  //transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  //opacity: 0;
}

.todo-by {
  font-size: 0.8rem;
  font-weight: 100;
  color: $gray-500;
}

.todo-delete {
  svg {
    color: $gray-500;
    &:hover {
      color: $gray-800;
    }
  }
}

.input-group .todo-notes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
  margin-top: 0.5rem;

  svg {
    font-size: 1.6rem;
    font-weight: 100;
    cursor: pointer;
    color: $gray-500;

    &:hover {
      color: $gray-800;
    }
  }
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

// Animation
// @keyframes swing-in-top-fwd {
//   0% {
//     transform: rotateX(-100deg);
//     transform-origin: top;
//     opacity: 0;
//   }
//   100% {
//     transform: rotateX(0deg);
//     transform-origin: top;
//     opacity: 1;
//   }
// }

// @keyframes scale-in-center {
//   0% {
//     transform: scale(0);
//     opacity: 1;
//   }
//   100% {
//     transform: scale(1);
//     opacity: 1;
//   }
// }

@keyframes scale-up-ver-top {
  0% {
    transform: scaleY(0.4);
    transform-origin: 100% 0%;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
  }
}

.todo-list-enter-active {
  //animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation: scale-up-ver-top 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) 0ms both;
  //animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.todo-list-leave-active {
  //animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  //animation: scale-up-ver-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) 0ms both;
  //animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  transition: opacity 0.2s;
}

.todo-list-enter {
  opacity: 0;
}

.todo-list-leave-to /* .list-leave-active below version 2.1.8 */ {
  //opacity: 0;
  //transform: translateX(-300px);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#listOfDoneTodos ul {
  padding: 0;
}

.done-todo {
  display: block;
  padding: 0.3rem 0.4rem;
  clear: both;

  .done-todo-notes {
    cursor: pointer;
    margin-left: .5rem;
  }

  &:hover {
    background: $gray-200;
  }

  &:not(:first-child) {
    border-top: 1px solid $gray-200;
  }
}

.done-todo-delete {
  display: block;
  float: right;
  font-size: 0.8rem;
  cursor: pointer;
  padding: 0 0.5rem;
  color: $red;
  clear: both;
}

.done-todo-restore {
  display: block;
  float: right;
  font-size: 0.8rem;
  cursor: pointer;
  padding: 0 0.5rem;
  color: $green;
}

.done-list-name,
.done-by {
  opacity: 0.5;
}

.done-by {
  font-style: italic;
  padding-left: 0.5rem;
}

.todo-inner:hover {
  background: $gray-100;
}

.form-control {
  background-color: transparent;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
}

.todo {
  margin-bottom: 0.2rem;
}

.todo-name {
  margin-bottom: 0.2rem;
}

.todo-inner {
  padding: 0.5rem;
}

.todo .input-group-prepend {
  height: 2.7rem;
}

.todo-move-up,
.todo-move-down {
  margin: .3rem;
  min-height: 20px;
  min-width: 20px;

  svg {
    cursor: pointer;

    &:hover {
      color: $blue;
    }
  }
}

.todo-description {
  margin: 0;
  padding: 0;
  font-size: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1rem;
  color: $gray-700;
  cursor: pointer;
}

.input-group textarea {
  background: $gray-300;
  width: 100%;
  border: 0;
  padding: 0.4rem;
  margin: 0.4rem 0;
  font-size: 0.8rem;
}
</style>