<template>
  <div class="container mt-3" v-observe-visibility="{ callback: visibilityChanged, throttle: 500 }">
    <div class="row no-gutters">
      <div class="col-md-10 py-2 p-3" id="list-container">
        <div class="row d-lg-none">
          <div class="col-2 align-self-center">
            <b-dropdown id="dropdown-left" text="" variant="secondary-outline" class="m-2">
              <b-dropdown-item v-for="list in lists" v-bind:key="list._id" @click="setActiveList(list._id)">{{ list.name }}</b-dropdown-item>
              <b-dropdown-item @click="openEditListModal()">Edit Lists</b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="col-10 align-self-center">
            <h4>{{ nameForList(activeList) }}</h4>
          </div>
        </div>
        <div class="row">
          <CreateTodo></CreateTodo>
        </div>
        <div class="row">
          <ListTodo></ListTodo>
        </div>
      </div>        
      <div class="col-md-2 mt-2 d-none d-lg-block" id="tab-list">
        <b-nav pills vertical>
          <b-nav-item
            class="mb-2"
            v-bind:class="[ list.active ? 'list-tab-active' : 'list-tab' ]"
            v-bind:key="list._id"
            v-for="list in lists"
            v-bind:active="list.active"
            @click="setActiveList(list._id)"
          >{{ list.name }}</b-nav-item>
        </b-nav>
        <ListEdit></ListEdit>
      </div>
    </div>
  </div>
</template>

<script>
import CreateTodo from "./components/CreateTodo.vue";
import ListTodo from "./components/ListTodo.vue";
import ListEdit from "./components/ListEdit.vue";
import bus from "./bus.js";

export default {
  name: "app",
  components: { CreateTodo, ListTodo, ListEdit },
  data() {
    return {
      activeList: "",
      lists: [],
      isConnected: false,
      socketMessage: ''
    };
  },

  sockets: {
    connect() {
      // Fired when the socket connects.
      this.isConnected = true;
    },

    disconnect() {
      this.isConnected = false;
    },

    // Fired when the server sends something on the "todo" channel.
    todo() {
      bus.$emit("refreshTodo");
    },

    // Fired when the server sends something on the "list" channel.
    list() {
      bus.$emit("refreshLists");
    }
  },

  methods: {

    visibilityChanged: function() {
        bus.$emit("refreshTodo");
        bus.$emit("refreshLists");
    },

    setActiveList(id) {
      this.$cookies.set('activeList', id);
      if (id == this.activeList) return;
      var app = this;
      app.activeList = 0;
      bus.$emit("changeActiveList", 0);
      app.$forceUpdate();

      // set delay to simulate out-in transition and prevent content shifting
      setTimeout(function() {
        app.activeList = id;
        Object.keys(app.lists).forEach(function(key) {
          app.lists[key].active = app.lists[key]._id == id;
        });
        bus.$emit("changeActiveList", id);
        app.$forceUpdate();
      }, 300);

    },

    nameForList: function(listId) {
      var app = this;
      var name = "";
      Object.keys(app.lists).forEach(function(key) {
        if (app.lists[key]._id === listId) {
          name = app.lists[key].name;
        }
      });
      return name;
    },

    openEditListModal() {
      bus.$emit("openEditListModal");
    },

    listenToEvents() {
      bus.$on("updateLists", lists => {
        this.lists = lists;
        this.setActiveList(this.activeList);
      });
    }
  },

  mounted: function() {
    if (this.$cookies.isKey('activeList') && this.$cookies.get('activeList') != 0) {
      this.setActiveList(this.$cookies.get('activeList'));
    }
    else {
      this.setActiveList("main");
    }
    this.listenToEvents();
  },
};
</script>

<style lang="scss">
#list-container {
  background: $gray-200;
}

#tab-list {
  ul {
    width: 100%;
  }
  .nav-item {
    a {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .list-tab {
    margin-right: 7px;

    a {
      background: linear-gradient(90deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.1) 7%, rgba(0,0,0,0.1) 100%);
      width: 100%;  

      &:hover {
        background: $gray-200;
      }
    }
  }

  .list-tab-active {
    outline: none;
    &:focus {
      outline: none !important;
    }
    a {
      background: $gray-200;
      width: auto;
      padding-right: 10px;
      font-size: 110%;
      transition-property: width height;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
      outline: none;
      &:focus {
        outline: none;
      }
      color: $gray-800;
    }
  }
}
</style>
