<template>
  <div>
    <a href="#" id="link-edit-lists" class="d-block ml-3 mt-2 mr-3" @click="openModal()">Edit Lists</a>
    <b-modal id="edit-lists" ref="edit-lists" centered title="Edit Lists" hide-footer>
      <template v-slot:modal-title>
        <img src="./../assets/logo.png" style="height: 1.6rem; float: left; margin-right: .4rem">Edit Lists
      </template>

      <ul class="list-of-lists">
        <li v-for="(list, index) of lists" v-bind:key="list._id">
          <span class="list-move-buttons">
            <span class="list-move-up">
              <b-icon-caret-up-fill
                v-show="list._id != 'main'"
                v-if="index > 1"
                @click="listMoveUp(index); updateListOrder();"></b-icon-caret-up-fill>
            </span>
            <span class="list-move-down">
              <b-icon-caret-down-fill
                v-show="list._id != 'main'"
                v-if="index != lists.length - 1"
                @click="listMoveDown(index); updateListOrder();"></b-icon-caret-down-fill>
            </span>
          </span>

          <span class="list-name" @click="dialogRenameList(list)">{{ list.name }}</span>
          
          <span
            v-show="list._id != 'main'"
            v-if="list._id !== 'main'"
            class="list-delete"
            @click="dialogDeleteList(list)"
          >
            <b-icon icon="x"></b-icon>
          </span>
        </li>
      </ul>
      <p v-if="lists.length === 0">No lists. Create one!</p>
      <b-button variant="outline-secondary" @click="dialogNewList()">
        <b-icon icon="plus"></b-icon>Add list
      </b-button>
    </b-modal>
  </div>
</template>

<script>
Array.prototype.move = function(from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};

import bus from "./../bus.js";

export default {
  data() {
    return {
      todos: [],
      lists: [],
      defaultListItem: [
        {
          _id: "main",
          name: "☑️Main",
          icon: "",
          photoURL: ""
        }
      ]
    };
  },
  methods: {
    fetchList() {
      var app = this;
      this.$http.get("/list/").then(response => {
        app.lists = this.defaultListItem.concat(response.data);
        app.doneLoading = true;
        bus.$emit("updateLists", app.lists);
      });
    },

    addList(list) {
      let newList = {
        name: list.name
      };
      var app = this;
      this.$http
        .post("/list/", newList)
        .then(function() {
          app.fetchList();
        })
        .catch(error => {
          console.log(error);
        });
    },

    updateList(list) {
      let id = list._id;
      var app = this;
      this.$http
        .put(`/list/${id}`, list)
        .then(function() {
          app.fetchList();
        })
        .catch(error => {
          console.log(error);
        });
    },

    updateListOrder() { 
      var app = this;
      Object.keys(app.lists).forEach(function(index) {
        app.lists[index].order = index;
        app.updateList(app.lists[index]);
      })
    },

    deleteList(list) {
      var app = this;
      var id = list._id;
      this.$http
        .delete(`/list/${id}`)
        .then(function() {
          app.fetchList();
        })
        .catch(error => {
          console.log(error);
        });
    },

    listMoveUp(index) {
      this.lists.move(index, index - 1);
    },

    listMoveDown(index) {
      this.lists.move(index, index + 1);
    },

    dialogDeleteList(list) {
      if (this._.filter(this.todos, { list: list._id }).length > 0) {
        alert(
          "You need to delete all the todos in this list before deleting the list."
        );
      } else {
        if (confirm("Are you sure you want to delete this list?")) {
          this.deleteList(list);
        }
      }
    },

    dialogRenameList(list) {
      // @TODO Check if there are lists by the same name
      let newName = "";
      if (
        (newName = prompt("Enter a new name for " + list.name, list.name)) !==
          null &&
        newName.length > 0
      ) {
        let nameTaken =
          this._.filter(this._.reject(this.lists, { _id: list._id }), {
            name: newName
          }).length > 0;

        if (nameTaken) {
          alert("Sorry! Name already taken.");
        } else {
          list.name = newName;
          this.updateList(list);
          bus.$emit("updateLists", this.lists);
        }
      }
    },

    dialogNewList() {
      let newName = "";
      if (
        (newName = prompt("Enter a name for the new list:")) !== null &&
        newName.length > 0
      ) {
        this.addList({ name: newName });
      }
    },

    openModal() {
      this.$refs["edit-lists"].show();
    }
  },

  created: function() {
    this.fetchList();
    bus.$on("updateTodos", todos => {
      this.todos = todos;
    });
    bus.$on("openEditListModal", () => {
      this.openModal();
    });
    bus.$on("refreshLists", () => {
      this.fetchList();
    });
  }
};
</script>

<style lang="scss">
.list-delete {
  float: right;
  cursor: pointer;

  &:hover {
    color: red;
  }
}

.list-of-lists {
  padding: 0;
  li {
    .list-name {
      cursor: pointer;
    }

    list-style-type: none;
    padding: 0.4rem 0.9rem;
    border-radius: 4px;

    .list-edit {
      display: none;
      padding: 0 1rem;
      font-weight: 100;
      font-size: 70%;
    }
  }
  li:hover {
    background-color: rgba(0, 0, 0, 0.2);

    .list-edit {
      display: inline;
    }
  }
}

button.btn {
  display: block;
  margin: 0 auto;
}

#link-edit-lists {
  &:link {
    color: $gray-600;
  }
  &:visited {
    color: $gray-600;
  }
  &:hover {
    color: $gray-700;
  }
  &:focus {
    color: $primary;
  }
  &:active {
    color: $primary;
  }
}

.list-move-up,
.list-move-down {
  display: inline-block;
  min-width: 20px;
}

.list-move-buttons {
  width: 40px;
  display: block;
  float: left;

  svg {
    color: $gray-500;
  }

  svg:hover {
    color: $blue;
    cursor: pointer;  
  }
}
</style>